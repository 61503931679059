<template>
  <page-layout>
    <div class="wrapper dashboard">
      <div class="title-wrap">
        <h3 class="page-titles">
          Customer Accounts
        </h3>
        <p class="">
          Manage your customers
        </p>
      </div>
      <div class="flex items-center">
        <label
          for="simple-search"
          class="sr-only"
        >Search</label>
        <div class="relative w-80">
          <div class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
            <svg
              aria-hidden="true"
              class="w-5 h-5 text-gray-500 dark:text-gray-400"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            ><path
              fill-rule="evenodd"
              d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
              clip-rule="evenodd"
            /></svg>
          </div>
          <input
            type="text"
            id="filter"
            name="filter"
            v-model="filter"
            class="block w-full p-2 pl-10 text-sm text-gray-900 border border-gray-300 rounded-md bg-gray-50 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Search"
            required
          >
        </div>
        <button
          @click="filtration()"
          class="text-white btn-primary-small   mx-2"
        >
          Search
        </button>
        <button
          @click="clearFiltration()"
          class="text-white btn-primary-small  "
        >
          Clear
        </button>
      </div>
      <table class="table mt-4 overflow-hidden table-compact table-zebra w-full">
        <thead>
          <tr>
            <th>
              <div class="flex space-x-4">
                <dropdown-table-header> Organization Name </dropdown-table-header>
                <div />
              </div>
            </th>
            <th>
              <div class="flex space-x-4">
                <dropdown-table-header> Actions </dropdown-table-header>
                <div />
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-if="showLoading || organizations.length <= 0">
            <td colspan="2">
              <div class="flex items-center justify-center w-full h-full">
                <button
                  v-if="showLoading"
                  class="btn btn-lg btn-circle btn-ghost loading"
                />
                <span v-if="organizations.length <= 0"> No Organizations </span>
              </div>
            </td>
          </tr>
          <tr
            v-for="organization in organizations"
            :key="organization.id"
          >
            <td class="text-base">
              {{ organization.name }}
            </td>
            <td>
              <button
                class="btn-outline-small"
                @click="loginToOrganization(organization)"
              >
                Login
              </button>
            </td>
          </tr>
        </tbody>
      </table>
      <div
        class="relative z-10"
        aria-labelledby="modal-title"
        role="dialog"
        aria-modal="true"
        v-if="modal"
      >
        <div class="fixed inset-0 transition-opacity bg-gray-500 bg-opacity-75" />

        <div class="fixed inset-0 z-10 overflow-y-auto">
          <div class="flex items-end justify-center min-h-full p-4 text-center sm:items-center sm:p-0">
            <div class="relative overflow-hidden text-left transition-all transform bg-white rounded-lg shadow-xl sm:my-8 sm:w-full sm:max-w-2xl">
              <div class="px-4 pt-5 pb-4 bg-white sm:p-6 sm:pb-4">
                <div class="sm:flex sm:items-start">
                  <div class="flex items-center justify-center flex-shrink-0 w-12 h-12 mx-auto bg-gray-100 rounded-full sm:mx-0 sm:h-10 sm:w-10">
                    <svg
                      class="w-6 h-6 text-green-600"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M12 10.5v3.75m-9.303 3.376C1.83 19.126 2.914 21 4.645 21h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 4.88c-.866-1.501-3.032-1.501-3.898 0L2.697 17.626zM12 17.25h.007v.008H12v-.008z"
                      />
                    </svg>
                  </div>
                  <div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                    <h3
                      class="text-lg font-medium leading-6 text-gray-900"
                      id="modal-title"
                    >
                      Login as {{ selectedOrganizations.name }}
                    </h3>
                    <div class="mt-2">
                      <p class="text-sm text-gray-500">
                        If you want to logout from {{ selectedOrganizations.name }} orgnisation please click logout
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="px-4 py-3 bg-gray-50 sm:flex sm:flex-row-reverse sm:px-6">
                <button
                  type="button"
                  class="inline-flex justify-center w-full px-4 py-2 mt-3 text-base font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                  @click="stayAsLogged()"
                >
                  Okay
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </page-layout>
</template>

<script>
import DropdownTableHeader from '../components/DropdownTableHeader.vue'
import PageLayout from '@/layouts/PageLayout.vue'
import JwtDecode from 'jwt-decode'
export default {
  components: {
    DropdownTableHeader,
    PageLayout
  },
  data () {
    return {
      organizations: [],
      showLoading: false,
      selectedOrganizations: {},
      modal: false,
      filter: ''
    }
  },
  async mounted () {
    this.showLoading = true
    await this.fetchAllOrganizations()
    this.showLoading = false
  },

  methods: {
    async fetchAllOrganizations () {
      this.organizations = await this.$api.getAllOrganizations()
    },
    stayAsLogged () {
      this.modal = false
    },
    async loginToOrganization (org) {
      this.selectedOrganizations = org
      try {
        const res = await this.$api.loginToOrganizationAsAdmin(this.selectedOrganizations.id)
        const decoded = JwtDecode(res.token)

        this.modal = true
        this.$store.commit('USER/SET_USER', {
          token: res.token,
          firstName: ' ',
          lastName: ' ',
          admin: decoded.admin,
          organizationsName: decoded.organisationName,
          organizationId: decoded.organisation
        })
      } catch (err) {
        console.log('error', err)
      }
    },
    async filtration () {
      try {
        const res = await this.$api.getFilteredOrgization(this.filter)
        this.organizations = res.organisation
      } catch (err) {
        console.log('error', err)
      }
    },
    async clearFiltration () {
      try {
        await this.fetchAllOrganizations()
      } catch (err) {
        console.log('error', err)
      }
    }
  }
}
</script>

<style>
</style>
